import React, { Component } from 'react'

export default class Titles extends Component {
    render() {
        return (
            <section className='no-padding-bottom'>
                <div className='titles'>
                    <h2>{this.props.title || 'Error loading title'}</h2>
                    <h3>{this.props.subtitle || 'Error loading subtitle'}</h3>
                </div>
            </section>
        )
    }
}
