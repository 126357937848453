import React, { Component } from 'react'
import '../styles/scss/scrollGallery.scss'
import ScrollGallery from './Common/ScrollGallery'
import Titles from './Common/Titles'
import axios from 'axios'

export default class Games extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'games',
                title: {
                    text: 'Games',
                },
                subtitle: {
                    text: '(VR)',
                },
                gallery: [
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/metacartel.png',
                        title: 'Metacartel©',
                        content: `
                        Lock and load in Meta Cartel, the adrenaline-fueled VR shooter game that will put your skills to the test! Experience heart-pumping action as you take on enemy combatants in intense battles.
                        `
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/shootingrange.png?1680008831',
                        title: 'Shooting Range',
                        content: `
                        Experience the adrenaline rush of a realistic VR shooting range game. Choose your weapon, take aim, and test your accuracy against challenging targets.
                        Try to beat your friends' highest scores!
                        `                   
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/race_game.png',
                        title: 'Racing game',
                        content: `
                        Get ready to speed through the most intense VR racing experience yet. Our game offers wonderful vehicles, tracks, and challenges to put your skills to the test. Compete against friends or the world and see who's the ultimate racer.
                        `                    
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/tenis_game.png',
                        title: 'Tennis court',
                        content: `
                        Serve, volley, and smash your way to victory in our VR tennis game! Whether you're a seasoned player or a beginner, our VR tennis game is the perfect choice for a fun and challenging experience.
                        `                    
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/arcade_room.png',
                        title: 'Arcade room',
                        content: `
                        Step into our arcade room and immerse yourself in a world of excitement! With a variety of classic and modern games, there's something for everyone. Get ready for endless fun and competition! 
                        `                    
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/Kicker.png',
                        title: 'Kicker',
                        content: `
                        Kick your way to victory in our exciting kicker game! Perfect your technique and challenge your friends to a fast-paced game of soccer. With realistic physics and stunning graphics, you'll feel like you're on the field. 
                        `                   
                    },
                ]
            }
        }
    }

    componentDidMount = () => {
        this.updatePageData();
    }

    updatePageData = () => {
        axios.get(`https://versemail.azurewebsites.net/page/${this.state.page?.name}`).then(response => {
            if (response?.data) {
                this.setState({
                    page: {
                        ...this.state.page,
                        ...response?.data
                    },
                    originalPage: {...response?.data}
                })
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    render() {
        return (
            <div id='games'>
                <Titles
                    title={this.state.page?.title?.text}
                    subtitle={this.state.page?.subtitle?.text}
                />

                <ScrollGallery 
                    gallery={this.state.page?.gallery}
                />
            </div>
        )
    }
}
