import React, { Component } from 'react'
import Modal from '../components/Common/Modal'
import Layout from '../layouts/Layout'
import '../styles/scss/motion.scss'
import Titles from './Common/Titles'
import axios from 'axios'

export default class Motion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //the visible part of the scroll element
            slidingWindow:0,
            //card width in viewport width divided by 100 (in this example : card-width = 66 vw)
            cardWidthRatio:this.getCardWidth(),
            //percentage of card expansion
            maxCardPaddingBottom:this.getMaxPaddingBottom(),
            //intial/idle card bottom 
            minCardPaddingBottom:this.getMinPaddingBottom(),
            // expansion difference
            cardPAddingBottomDifference:this.getMaxPaddingBottom() - this.getMinPaddingBottom(),
            // gaps between cards
            cardsGap: this.getCardsGap(),
            page: {
                name: 'motion-capture',
                title: {
                    text: 'Motion',
                },
                subtitle: {
                    text: 'capture',
                },
                gallery: [
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/face_motion_capture.jpg',
                        title: 'face motion capture',
                        content: `Face Motion Capture (FMC) is a technology used to digitally record and analyze facial expressions and movements. It typically involves the use of markers or sensors placed on the face, which are then tracked by cameras or other sensors to capture the movement data. The resulting data can be used to create a detailed and accurate digital representation of the face's motion. FMC has become an increasingly important tool in the entertainment industry, as it allows for the creation of realistic and convincing digital characters and performances.`,
                       
                        paddingBottom : this.getMaxPaddingBottom(),
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/body_motion_capture_1_.jpg',
                        title: 'body motion capture',
                        content: `(BMC) is a technology used to digitally record and analyze human movement. It typically involves the use of markers or sensors placed on the body, which are then tracked by cameras or other sensors to capture the movement data. In the entertainment industry, BMC is used to create realistic and convincing animation, especially in movies, video games and the digital world. Overall, Body Motion Capture is a rapidly evolving field that continues to offer new and exciting opportunities for capturing, analyzing, and understanding human movement. Its ability to provide detailed and accurate digital representations of human movement makes it a valuable tool for a variety of industries and applications.`,
                        paddingBottom : this.getMinPaddingBottom(),
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/Hands_motion_capture.jpg',
                        title: 'hands motion capture',
                        content: `(HMC) is a technology used to digitally record and analyze hand movements. It typically involves the use of markers or sensors placed on the hand, which are then tracked by cameras or other sensors to capture the movement data. 
                        HMC has become an increasingly important tool in fields such as animation, virtual reality, and gaming, as it allows for the creation of realistic and convincing hand movements and gestures.`,
                      
                        paddingBottom : this.getMinPaddingBottom()
                    }
                ]
            }
        }
    }
    getCardWidth = () => {
        // set the card width / viewport width ratio
        return 0.44;
    } 
    getMaxPaddingBottom = () =>{
        // set the maximum vlaue of the card's bottom padding
        return 137;
    }
    getMinPaddingBottom = () =>{
        // set the idle value of the card's bottom padding
        return 100;
    }
    getCardsGap = () => {
        // enter the gap value between cards
        return 20;
    }

    setCardBottomPadding = (i,paddingBottomValue) =>{
        var modifiedGallery=this.state.page.gallery.map((element,index) => {
            if(i==index){
                element.paddingBottom = paddingBottomValue;
            }
            return element;
        });
        this.setState({
            ...this.state,
            page:{
                ...this.state.page,
                gallery:modifiedGallery
            }
        });
    }

    manageSlidingMechanism = (scroll) =>{
        const gallery = document.querySelectorAll("#ic");
        const cardsNumber = gallery.length;
        scroll.addEventListener("scroll", (event) => {
            // index of the element depending on the scroll offset
            let index = Math.floor(scroll.scrollLeft / this.state.slidingWindow);
            // the requiered value to reach the next card
            let scrollThreshold = this.state.slidingWindow * index;
            // scroll value left to reach the next index
            let scrollDifference = scroll.scrollLeft - scrollThreshold;
            // card width
            let cardWidth = window.innerWidth * this.state?.cardWidthRatio;
            // we used normalised values to increase the padding proportionaly with the rate of scrolling 
            let scrollingRate = scrollDifference / ( cardWidth + this.state?.cardsGap) 
            // the current value of the bottom padding of the card
            let currentCardPaddingBottom = scrollingRate * this.state?.cardPAddingBottomDifference;
            // limiting the expansion
            let limitedCardPaddingBottom = Math.min(currentCardPaddingBottom,this.state?.cardPAddingBottomDifference);
            // decresing the bottom padding for the current element
            this.setCardBottomPadding(index, -limitedCardPaddingBottom + this.state?.maxCardPaddingBottom);
            // limiting changes to the penultimate element
            if(index<(cardsNumber-1)){
                // increasing the bottom padding of the targeted element
                this.setCardBottomPadding(index+1, limitedCardPaddingBottom + this.state?.minCardPaddingBottom);
            }
        })
    }

    componentDidMount = () => {
        var scroll = document.getElementById("motion");
        var slidingWindow=((scroll.scrollWidth - window.innerWidth) / 2);
        this.setState({slidingWindow:slidingWindow})
        window.addEventListener("resize", () => {
            this.isSmall = window.innerWidth < 769;

            if (!this.isSmall)
                this.initCardsPadding()

            var slidingWindow=((scroll.scrollWidth - window.innerWidth) / 2);
            this.setState({slidingWindow:slidingWindow})
        });

        this.manageSlidingMechanism(scroll);
        this.updatePageData();
    }

    updatePageData = () => {
        axios.get(`https://versemail.azurewebsites.net/page/${this.state.page?.name}`).then(response => {
            if (response?.data) {
                this.setState({
                    page: {
                        ...this.state.page,
                        ...response?.data
                    },
                    originalPage: {...response?.data}
                })
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    initCardsPadding = () => {
        if (this.state.page?.gallery?.length)
            this.setState({
                ...this.state,
                page:{
                    ...this.state.page,
                    gallery: this.state.page?.gallery.map(element => {
                        element.paddingBottom = 0;
                        return element
                    })
                }
            });
    }
    
    renderMotionGallery = () => {
        if (!this.state.page?.gallery?.length) return null;

        return this.state.page.gallery.map((element, index) => {
            return (
                <div className='image-container'  style={{animationDuration: ((index + 1) * .5) + 's'}}>
                    <div 
                        id='ic'
                        className={'image ' + element?.class} 
                        style={{ 
                            backgroundImage: `url(${element?.source})`,
                            paddingBottom: this.isSmall ? `${element.paddingBottom}%` : '',
                        }}
                        onClick={() => this.selectElement(element,index)}
                    >
                        <div className='overlay'>
                            <div className='title-container'>
                                <div className='title'>
                                    {element?.title}
                                </div>
                                <div className='details-button'>
                                    View details
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderEmptyElement = () => {
        return (
            <div className='image-container' style={{minWidth: 'unset'}}>
                <div 
                    className={'image '} 
                    style={{ 
                        paddingBottom: this.isSmall ? '137%' : '115%',
                        width: '1px',
                        opacity: 0,
                        minWidth: 'unset',
                    }}
                >
                    <div className='overlay'/>
                </div>
            </div>
        )
    }

    selectElement = (element,index) => {
        var scroll = document.getElementById("motion");
        if(window.innerWidth < 769){
            scroll.scrollLeft = this.state.slidingWindow*index;
        }
        
            this.setState({
                selectedElement: element
            })
        
    }

    render() {
        return (
            <div id='motion-capture'>
                {
                    this.state.selectedElement &&
                        <Modal
                            selectedElement={this.state.selectedElement}
                            closeModal={this.selectElement}
                        />
                }
                
                <Titles 
                    title={this.state.page?.title?.text}
                    subtitle={this.state.page?.subtitle?.text}
                />

                <section className='motion-content' id='motion'>
                    <div className='horizontal-gallery' id='scroll'>
                        {this.renderMotionGallery()}
                        {this.renderEmptyElement()}
                    </div>
                </section>
            </div>
        )
    }
}
