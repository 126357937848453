import React, { Component } from 'react'
import Modal from '../components/Common/Modal'
import Games from '../components/Games'
import Motion from '../components/Motion'
import Layout from '../layouts/Layout'
import '../styles/scss/motion.scss'

export default class games extends Component {
    render() {
        return (
            <Layout>
                <Games /> 
            </Layout>
        )
    }
}
