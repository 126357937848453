import React, { Component } from 'react'

export default class ScrollGallery extends Component {
    constructor(props){
        super(props);
        this.ref= React.createRef();
        this.state = {
            isScrolling: false,
            isDragged:false,
            clientX: 0,
            scrollX: 0
        };
    }

    onMouseDown = (e) => {
        this.setState({
            ...this.state,
            isScrolling: true, 
            isDragged:false,
            clientX: e.clientX,
        });
    };
    onMouseUp = (e) => {
        this.setState({ 
            ...this.state,
            isScrolling: false,
        });
    };
    onMouseMove = (e) => {
        const { clientX, scrollX } = this.state;
        if (this.state.isScrolling ) {
          this._ref.scrollLeft = this._ref.scrollLeft - e.clientX + clientX;
          this.setState({
            ...this.state,
            isDragged:true,
            scrollX : this._ref.scrollLeft - e.clientX + clientX,
            clientX:e.clientX,
          })
        }
      };
    
    handleClick = (element) => {
        if (this.props.selectElement && !this.state?.isDragged)
            this.props.selectElement(element)
    }

    renderEmptyGallery = (isEvent) => {
        return (
            <div className='empty-gallery'>
                <div className='empty-container'>
                    <div className={'illustration'}/>
                    There are no events yet...
                </div>
            </div>
        )
    }

    renderTitleContainer = (element) => {

        if (!element?.event) {
            if (element?.isComingSoon) {
                return <div className='coming-soon' />
            } else
                return (
                    <>
                        <div className='title-container'>
                            <div className='title'>
                                {element?.title}
                            </div>
                        </div>
                        <div className='title-container open'>
                            <div className='title'>
                                {element?.title}
                            </div>
                            <div className='content'>
                                {element?.content}
                            </div>
                        </div>
                    </>
                )
        }
        
    }

    renderJoin = (element) => {
        if (element?.event) {
            return (
                <div className='event-options'>
                    <div className={'event-status ' + element?.event?.status}/>
                    <div className='join-btn'>
                        Join
                    </div>
                </div>
            )
        }
    }

    renderGallery = (gallery) => {
        if (!gallery?.length) return this.renderEmptyGallery();

        return gallery.map((element, index) => {
            return (
                <div className='image-container normal' style={{animationDuration: (.5 + (index) * .25) + 's'}} onClick={() => this.handleClick(element)}>
                    <div 
                        className={'image ' + element?.class} 
                        style={{ 
                            backgroundImage: `url(${element?.source})`,
                        }}
                    >
                        <div className='overlay'>
                            {
                                this.renderTitleContainer(element)
                            }

                            {
                                this.renderJoin(element)
                            }
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        const ref = this.props;
        let {gallery} = this.props;
        return (
            <div className={'scroll-gallery-container '.concat(this.state.isScrolling ? "scrolling " : "")}
                ref={ref => this._ref = ref}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                id="scroll"
            >
                <div className={'scroll-gallery' + (!gallery?.length ? ' empty' : '')} 
                    
                >
                    {this.renderGallery(gallery)}
                </div>
            </div>
        )
    }
}

